/**
*Set globals variables for app
**/
import { Injectable } from '@angular/core';
@Injectable()
export class Globals {
  version : string="V1.6.0";
  mode : string = "devserver";
  baseUrl : string;
  publicPathBaseUrl : string;
  publicPathImage:string;
  limit : number;
  role : number;
  imgFolder : string;
  socketUrl: string;
  publicStoragePathBaseUrl: string;
  haveNotification: boolean = false;
  isConnected: boolean = false;
  webBaseUrl: string;
  paypalClientId : string=null;
  freePeriodEnd: number;
  constructor(){
    if(this.mode == "dev"){
      this.limit = 10;
      this.baseUrl = "http://localhost:8888/mma-connexion/mma-connection/public/api/";
      this.webBaseUrl = "http://localhost:8888/mma-connexion/mma-connection/public/";
      this.publicPathImage = "http://localhost:8888/mma-connexion/mma-connection/public/images/";
      this.publicPathBaseUrl = "http://localhost:8888/mma-connexion/mma-connection/storage/app/public/pictures/";
      this.publicStoragePathBaseUrl = "http://localhost:8888/mma-connexion/mma-connection/storage/app/public";
      //this.publicPathImage = "http://localhost/MesDossiers/mma-connection/mma-connection/public/images/";
      this.socketUrl = 'http://localhost:3001';
      this.paypalClientId = 'AXDjpVXI9j2hTKYgijCpqVoQucU2sEy6_H8z8oNi-yVvKk2gqUSaSC0u3gLvih6BmYTRBAhxBvHa7NLO';
    }
    if(this.mode == "prod"){
      this.limit = 50;
      this.baseUrl = "https://back.mma-connection.com/api/";
      this.webBaseUrl = "https://back.mma-connection.com/public";
      this.publicPathImage = "https://back.mma-connection.com/images/";
      this.publicPathBaseUrl = "https://back.mma-connection.com/storage/app/public/pictures/";
      this.publicStoragePathBaseUrl = "https://back.mma-connection.com/storage/app/public";
      this.socketUrl = 'https://socket.mma-connection.com';
      this.paypalClientId = 'AdxfdgVAKqALzOs7qLJbeZ_B3ZviMnL0XVEZd_1ubgoaaqw2-lgsDMH9l7Jr4xP9llmPQbJMHphvjJwK';
    }
    if(this.mode == "beta"){
      this.limit = 50;
      this.baseUrl = "https://back.mma-connection.com/api/";
      this.webBaseUrl = "https://back.mma-connection.com/public";
      this.publicPathImage = "https://back.mma-connection.com/images/";
      this.publicPathBaseUrl = "https://back.mma-connection.com/storage/app/public/pictures/";
      this.publicStoragePathBaseUrl = "https://back.mma-connection.com/storage/app/public";
      this.socketUrl = 'https://socket.mma-connection.com';
      this.paypalClientId = 'AXDjpVXI9j2hTKYgijCpqVoQucU2sEy6_H8z8oNi-yVvKk2gqUSaSC0u3gLvih6BmYTRBAhxBvHa7NLO';
    }
    if(this.mode == "devserver"){
      this.limit = 50;
      this.baseUrl = "https://devback.mma-connection.com/api/";
      this.webBaseUrl = "https://devback.mma-connection.com/public";
      this.publicPathImage = "https://devback.mma-connection.com/images/";
      this.publicPathBaseUrl = "https://devback.mma-connection.com/storage/app/public/pictures/";
      this.publicStoragePathBaseUrl = "https://devback.mma-connection.com/storage/app/public";
      this.socketUrl = 'https://socket.mma-connection.com';
      this.paypalClientId = 'AXDjpVXI9j2hTKYgijCpqVoQucU2sEy6_H8z8oNi-yVvKk2gqUSaSC0u3gLvih6BmYTRBAhxBvHa7NLO';
    }
  }
}
